import React from 'react';
import './styles/Values.css';
import service1 from '../Assets/service1.jpg';
import service2 from '../Assets/service2.jpg';
import service3 from '../Assets/service3.jpg';
// ... Import the rest of the images

const services = [
  {
    title: 'KETTERÄ',
    description: 'Olemme pieni ja ketterä toimija',
    image: service1,
  },
  {
    title: 'RATKAISUKESKEINEN',
    description: 'Kohtaamme asiat ratkaisukeskeisesti',
    image: service2,
  },
  {
    title: 'INNOVATIIVINEN',
    description: 'Tuomme uusia ja innovatiivisia näkökulmia yritysvastuun toteuttamiseen',
    image: service3,
  },
  // ... Add the rest of the services
];

function Values() {
  return (
    <div className="Services py-5">
        <h2 className="valueheader">Arvomme</h2>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-4">
              <div className="card mb-4 border-0">
                <img src={service.image} className="card-img-top" alt={service.title} />
                <div className="card-body">
                  <h5 className="card-title">{service.title}</h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Values;
