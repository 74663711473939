import React from 'react';
import './styles/Contact.css';

{/* 
function Contact() {
  return (
    <div className="Contact py-5" id="contact">
      <div className="container">
        <h2 className="mb-4">Ole yhteydessä</h2>
        <form>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea className="form-control" id="message" rows="4"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        <div className="contact-info mt-4">
          <p>Email: info@esgconsulting.com</p>
          <p>Phone: +1 (555) 123-4567</p>
          <p>Address: 123 ESG Street, Suite 100, New York, NY 10001</p>
        </div>
      </div>
    </div>
  );
}
*/}

function Contact() {
  return(
    <div className="contact-container">
      <h2 className="contact-info">Ole yhteydessä</h2>
      <p className="contact-info">Annika Liikamaa</p>
      <p className="contact-info">Puhelin: 0500 327528</p>
      <p className="contact-info">Sähköposti: liikamaa.annika (at) gmail.com</p>
    </div>
  );
}

export default Contact;
