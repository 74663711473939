import React from 'react';
import './styles/ServicesStyle.css';


function Services() {
    return (
      <div className="Services py-5">
        <h1>TULOSSA</h1>
        <br></br>
        <h2 className="h2-services">Yritysvastuun kartoitus</h2>
        <h2 className="h2-services">Toimialan vastuullisuusanalyysi</h2>
  
        <h2 className="h2-services">Yritysvastuutiekartan suunnittelu</h2>
  
        <h2 className="h2-services">Yritysvastuuraportti</h2>
      </div>
    );
  }
  
  export default Services;