import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Esg from './components/Esg';
import Main from './components/Main';
import Contact from './components/Contact';

import NewForm from './components/newform';
import Services from './components/Services';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Questionnaire" element={<NewForm />} />
        <Route path="/Services" element={<Services />}/>
        <Route path="/Blog" element={<Main postId="post1" />}/>
        <Route path="/Contact" element={<Contact/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
