import React from 'react';
import './styles/BottomSection.css';
import Icon from '../Assets/ratamoicon-light.PNG';


function About() {
    return (
      <div className="BottomSection py-5">
        <div className="container">
          <h2  className="my-header">Ratamon tarina</h2>
          <div className="icon-text">
            <img src={Icon} alt="My Icon" className="my-icon"/>
            <p>
                Ratamo sai alkunsa ajatuksesta piharatamosta - kasvista, jonka kaikki tiedämme. Piharatamo kasvaa pienimmästäkin asfaltin säröstä ja selviytyy karussakin ympäristössä.
                Uskomme, että jokainen yritys voi toimia vastuullisemmin, toimialasta riippumatta. Haluamme olla auttamassa yrityksiä löytämään oman yritysvastuupolkunsa. Jokainen yritys voi olla kuin piharatamo ja menestyä haastavassakin vastuullisuusympäristössä.
            </p>
          </div>

        </div>
      </div>
    );
  }

export default About;
