import React from 'react';
import './styles/About.css';

function About() {
  return (
    <div className="About py-5" id="#about">
      <div className="container">
        <h2 className="mb-4">Meistä</h2>
        <p className="about-p">
          RATAMO tarjoaa konsultointipalveluja vastuulliseen liiketoimintaan pienille ja keskisuurille yrityksille. Asiantuntijatiimimme on sitoutunut auttamaan yrityksiä parantamaan ympäristö-, sosiaali- ja hallintokäytäntöjään. Uskomme, että kestävä liiketoiminta on hyvää liiketoimintaa, ja olemme omistautuneet auttamaan yrityksiä navigoimaan vastuullisuusviidakossa.
        </p>
      </div>
    </div>
  );
}

export default About;
