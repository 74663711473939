import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './styles/Header.css';

// Import your logo and icon here
import Icon from '../Assets/ratamoicon.png';
import Logo from '../Assets/ratamologo.PNG';

function Header() {
  return (
    <div>
    <Navbar expand="lg" className="navbar">
      <Container fluid>
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="Icon" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link className="nav-link" to="/Services">
              PALVELUT
            </Link>
            <Link className="nav-link" to="/Questionnaire">
              YRITYSVASTUUTESTI
            </Link>
            <Link className="nav-link" to="/Blog">
              BLOGI
            </Link>
            <Link className="nav-link" to="/Contact">
              OTA YHTEYTTÄ
            </Link>
          </Nav>
        </Navbar.Collapse>
        <div className="nav-icon">
        <img src={Icon} alt="Icon" />
        </div>
      </Container>
    </Navbar>

    </div>
  );
}

export default Header;