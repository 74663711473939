import React from 'react';
import Values from './Values';
import About from './About';
import BottomSection from './BottomSection';
import './styles/Home.css';

function Home() {
  return (
    <div className="Home">

        <Values />
        <About />
        <BottomSection />
    </div>
  );
}

export default Home;
