import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import './styles/Main.css';

function BlogPost({ postId }) {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    fetch(`/api/posts/${postId}`)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Error fetching the post');
        }
      })
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error('Error fetching markdown file:', error));
  }, [postId]);

  return (
    <div className="blog-post">
      <ReactMarkdown ReactMarkdown className="react-markdown">{markdownContent}</ReactMarkdown>
    </div>
  );
}

export default BlogPost;
