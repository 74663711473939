import { useCallback } from 'react';
import React,{useState,useEffect} from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import axios from 'axios';

function NewForm() {

    const [qData, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const fetchData = () => {
      fetch("./questionsData.json")
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          setData(data);
          console.log(data);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
          console.log(error);
        });
    };
    useEffect(() => {
      fetchData();
    }, []);


  const survey = new Model(qData);
  const alertResults = useCallback(async (sender) => {
    let points = 0;
    const results = sender.data;
    console.log(sender.data);
    

// Check if results is an object
if (typeof results === 'object' && results !== null) {
  // Convert object values into an array
  const values = Object.values(results);

  // Iterate through the values to sum up the points
  values.forEach(value => {
    points += parseInt(value, 10);
  });
}
    // Prepare the email content
    const emailContent = `Total points: ${points}\n\nResults: ${JSON.stringify(results)}`;
  
    // Send the email via the API server
    try {
      await axios.post('http://localhost:5000/send-email', {
        to: 'teemu.niemi@phpoint.fi',
        subject: 'Survey Results',
        text: emailContent,
      });
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error while sending email:', error);
    }
  }, []);

  survey.onComplete.add(alertResults);

  return <h1> TULOSSA </h1>;
  {/*return <Survey model={survey} />;*/}
}

export default NewForm;