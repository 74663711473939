import React from 'react';
import './styles/Footer.css';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="p-footer">&copy; {new Date().getFullYear()} RATAMO. All rights reserved.</p>
            <p className='p-footer'>Y-Tunnus: 3253203-6</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
